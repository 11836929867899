<template>
    <div v-if="$store.category" id="event">
        <vue-headful :title="$store.metadata.title + ' | Edgeryders Events'" :description="getDescription($store.category.description)" />
        <Header :data="header" :meta="$store.metadata" />
        <Main>
            <Sidebar type="event" :event="$store.metadata.event" />
            <div class="md:pr-20 pb-10">
                <Content v-if="$store.views" :views="$store.views" />
            </div>
        </Main>
        <div class="w-full block" id="register" v-if="$store.views">
<!--             <Form type="event" :meta="$store.metadata.event" />
 -->        </div>
    </div>
</template>
<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar'
import Header from '@/components/Header.vue'
import Content from '@/components/Content.vue'
// import Form from '@/components/Form.vue'
export default {
    name: 'Event',
    data: function() {
        return {
            post: null
        };
    },
    methods: {

    },
    components: {
        Sidebar,
        Header,
        Content,
        // Form
    },
    created() {
      this.$store.type = 'event';
        window.scrollTo(0, 0);
        this.getEvent(this.$route.params.id);
        this.$store.blob.active = true;
        this.$store.blob.start = false;
    }
}
</script>
<style lang="scss">
main {
    @apply flex mx-auto;
    width: 100%;
    max-width: 1280px;
}
</style>